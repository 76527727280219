import React from 'react';
import "./menuLateral.css";
import { useNavigate } from "react-router";


export default function MenuLateral({ conteudo }) {
  const navigate = useNavigate();

  const openNav = () => {
    document.getElementById("mySidenav").style.width = "250px";
    document.getElementById("main").style.marginLeft = "250px";
  }

  const closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
  }

  return (
    <>

      <div id="mySidenav" className="sidenav2">
        <div>
          <span className="cursor closebtn" onClick={closeNav}><i class="material-icons">close</i></span>
        </div>

        <span className='cursor' onClick={() => navigate("/admin/vendas")}>Vendas</span>
        <span className='cursor' onClick={() => navigate("/admin/produtos")}>Produtos</span>
        <span className='cursor' onClick={() => navigate("/admin/cadastro/atributo")}>Cadastro de Atributos</span>
        <span className='cursor' onClick={() => navigate("/admin/estoque")}>Estoque</span>
        <span className='cursor' onClick={() => navigate("/admin/preco")}>Preço</span>

      </div>

      <label className='icone-abrir-menu cursor' onClick={openNav}><i class="material-icons">menu</i></label>

      <div id="main"> {conteudo} </div>
    </>
  );
};
