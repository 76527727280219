import React, { useState, useEffect } from 'react';
import { requesAmin } from "../../utils/functions";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import "./topoEstoque.css";

export default function TopoLancamentoEstoque({ inserirEstoque,removerEstoque, load }) {
    const [atributos, setAtributos] = useState(sessionStorage.getItem('atributos') ? JSON.parse(sessionStorage.getItem('atributos')) : false);
    const [modif, setModif] = useState(false)
    const [fotoSelecionada, setFotoSelecionada] = useState(false);

    useEffect(() => {
        if (!atributos) {
            requesAmin("/admin/produto/listagem/atributos", "GET").then((res) => {
                setAtributos(res.data.atributos)
                sessionStorage.setItem('atributos', JSON.stringify(res.data.atributos));
                setFotoSelecionada(false)
            }).catch((err) => { console.log(err) })
        }

    }, [modif]);


    const consultarImagem = (e) => {
        const produto = (document.getElementById('produto')).value;
        const tipo_tecido = (document.getElementById('tipo_tecido')).value;
        const cor = (document.getElementById('cores')).value;
        const ref = `${produto}_${tipo_tecido}_${cor}`;

        if (!produto || !tipo_tecido || !cor)
            return;

        setFotoSelecionada(`https://storage.googleapis.com/loja-move-on/${ref}/1.JPEG`);
    }


    return (

        <div className="row box-lancamento-estoque" >
            <div className="col s12 m10">

                <div className=" col s12 m6 m-10-lancamento-estoque">
                    <select className='no-autoinit select-formatado' id='produto' onChange={consultarImagem}>
                        <option value={''} selected disabled>Produto</option>
                        {atributos.produtos && atributos.produtos.map((item) => { return <option value={item.id}>{item.nome} </option> })}
                    </select>
                </div>

                <div className=" col s12 m6 m-10-lancamento-estoque">
                    <select className='no-autoinit select-formatado' id='tipo_tecido' onChange={consultarImagem}>
                        <option value={''} selected disabled>Tipo Tecido</option>
                        {atributos.tipo_tecido && atributos.tipo_tecido.map((item) => { return <option value={item.id}>{item.tipo_tecido} - {item.descricao}</option> })}
                    </select>
                </div>

                <div className=" col s12 m4 m-10-lancamento-estoque">
                    <select className='no-autoinit select-formatado' id='cores' onChange={consultarImagem}>
                        <option value={''} selected disabled >Cores</option>
                        {atributos && atributos.cores.map((item) => { return <option value={item.id}>{item.cor}</option> })}
                    </select>
                </div>

                <div className=" col s12 m4 m-10-lancamento-estoque" >
                    <select className='no-autoinit select-formatado' id='tamanhos'>
                        <option value={''} selected disabled >Tamanhos</option>
                        {atributos && atributos.tamanhos.map((item) => { return <option value={item.id}>{item.tamanho}</option> })}
                    </select>
                </div>

                <div className=" input-field col s12 m4 m-10-lancamento-estoque">
                    <input id="qtd" type="tel" className="validate" />
                    <label for="qtd">Quantidade</label>
                </div>
            </div>

            <div className="col s12 m2 text-center">
                <img src={fotoSelecionada} className="img-foto-upload materialboxed" />
            </div>

            <div className="input-field  col s6">
                {!load && <a className="waves-effect waves-light btn w-100 adicionar" onClick={inserirEstoque}>Adicionar</a>}
            </div>
            <div className="input-field  col s6">
                {!load && <a className="waves-effect waves-light btn w-100 remover" onClick={removerEstoque}>Remover</a>}
            </div>

        </div >

    );
};
