import "./login.css";
import * as React from "react";
import { requesAmin } from "../../../utils/functions";
import { setSessionStorage } from "../../../utils/sessionStorage";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export default function Login() {

    const handleKeyDown = (e) => { if (e.key === 'Enter') { handleClick(); } }

    const handleClick = (e) => {
        const idToast = toast.loading("Aguarde um pouco...")

        const header = {
            "usuario": document.getElementById('usuario').value,
            "senha": document.getElementById('senha').value
        }

        requesAmin("/auth/login", "GET", false, header).then((res) => {
            toast.dismiss(idToast);

            if (res.data.erro) {
                toast.error(res.data.msg);
            } else {
                setSessionStorage("2bw0FFmJOu", { usuario: res.data.usuario, tipo: res.data.tipo, token: res.data.token });

                switch (res.data.tipo) {
                    case 1:
                    case 2:
                        window.location.href = "/admin/vendas";
                        break;
                    case 4:
                        window.location.href = "/admin/estoque";
                        break;
                    default:
                        window.location.href = "/login";
                        break;
                }

            }
        })
    };


    return (<>
        <div className="altura-tela">
            <div className="container-login">
                <div className="card card-login box-login">
                    <div className="card-content">
                        <span className="card-title">Log In</span>
                        <div className="input-field">
                            <input id="usuario" type="text" autofocus className="validate" onKeyDown={handleKeyDown} />
                            <label for="usuario">Usuário</label>
                        </div>
                        <div className="input-field">
                            <input id="senha" type="password" className="validate" onKeyDown={handleKeyDown} />
                            <label for="senha">Senha</label>
                        </div>
                        <div className="input-field m-t-30">
                            <span className="w-100 waves-effect waves-light btn-large cursor" onClick={handleClick}  >Login</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
};
