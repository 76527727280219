import React, { useState, useEffect } from 'react';
import "./topoLancamentoVendas.css";
import { requesAmin } from "../../utils/functions";


export default function TopoLancamentoVendas({ inserirVendas }) {
    const [atributos, setAtributos] = useState(false);

    useEffect(() => {
        requesAmin("/admin/produto/listagem/atributos", "GET").then((res) => {
            setAtributos(res.data.atributos)
        }).catch((err) => { console.log(err) })
    }, []);

    return (

        <div className="row box-lancamento-vendas" >
            <div className="col s12">

                <div className=" col s12 m4 m-10-lancamento-vendas">
                    <select className='no-autoinit select-formatado' id='produto'>
                        <option value={''} selected disabled>Produto</option>
                        {atributos.produtos &&
                            atributos.produtos.map((item) => { return <option value={item.id}>{item.nome} </option> })
                        }
                    </select>
                </div>


                <div className=" col s12 m4 m-10-lancamento-vendas">
                    <select className='no-autoinit select-formatado' id='tipo_tecido'>
                        <option value={''} selected disabled>Tipo Tecido</option>
                        {atributos.tipo_tecido &&
                            atributos.tipo_tecido.map((item) => { return <option value={item.id}>{item.tipo_tecido} - {item.descricao}</option> })
                        }
                    </select>
                </div>

                <div className=" col s12 m4 m-10-lancamento-vendas" >

                    <select className='no-autoinit select-formatado' id='tamanhos'>
                        <option value={''} selected disabled >Tamanhos</option>
                        {atributos &&
                            atributos.tamanhos.map((item) => { return <option value={item.id}>{item.tamanho}</option> })
                        }
                    </select>

                </div>
            </div>



            <div className="col s12 align-center-lancamento-vendas">
                <div className=" col s12 m4 m-10-lancamento-vendas">
                    <select className='no-autoinit select-formatado' id='cores'>
                        <option value={''} selected disabled >Cores</option>
                        {atributos &&
                            atributos.cores.map((item) => { return <option value={item.id}>{item.cor}</option> })
                        }
                    </select>
                </div>

                <div className=" col s12 m4 m-10-lancamento-vendas " >

                    {atributos &&
                        <select className='no-autoinit select-formatado' id='forma_pagamento'>
                            <option value={''} selected disabled >Forma de pagamento</option>
                            {atributos &&
                                atributos.forma_pagamento.map((item) => { return <option value={item.id}>{item.forma_pagamento}</option> })
                            }
                        </select>
                    }

                </div>

                <div className=" input-field col s6 m2 m-10-lancamento-vendas">
                    <input id="valor" type="tel" className="validate" />
                    <label for="valor">Valor</label>
                </div>

                <div className="input-field  col s6 m2 m-10-lancamento-vendas">
                    <input id="desconto" type="tel" className="validate" />
                    <label for="desconto">Desconto</label>
                </div>

            </div>
            <div className="input-field  col s12">
                <a className="waves-effect waves-light btn w-100" onClick={inserirVendas}>Adicionar</a>
            </div>

        </div >

    );
};
