import React, { useState, useEffect } from 'react';
import "./preco.css";
import TituloSeparacao from "../../../componentes/tituloSeparacao/tituloSeparacao";
import { requesAmin, formatPrice } from "../../../utils/functions";
import TopoPreco from "../../../componentes/topoPreco/topoPreco";
import "react-toastify/dist/ReactToastify.css";
import MenuLateral from "../../../componentes/menuLateral/menuLateral";


export default function Preco({ setCss }) {

    const [load, setLoad] = useState(true);
    const [dados, setDados] = useState(false);

    useEffect(() => {
        requesAmin("/admin/produto/preco/consultar", "GET").then((res) => {
            setDados(res.data.dados)
            setLoad(false)
            setCss(Math.random(9999));
        }).catch((err) => { console.log(err) })
    }, [load]);


    return (<>
        <MenuLateral conteudo={

            <div className="laterais altura-tela">
                <TituloSeparacao texto={"Cadastrar produto"} />
                <TopoPreco setCss={setCss} />

                <TituloSeparacao texto={"Listagem de Estoque"} />
                <div className="row box-lancamento-vendas" >
                    <div className="col s12">
                        <table class="responsive-table striped centered">
                            <thead>
                                <tr>
                                    <th>PRODUTO</th>
                                    <th>TIPO</th>
                                    <th>COR</th>
                                    <th>VALOR</th>
                                    <th>DESCONTO</th>
                                    <th>FOTO</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dados &&
                                    dados.map((item) => {

                                        const url = `https://storage.googleapis.com/loja-move-on/${item.ref}/1.JPEG`
                                        return <tr id={item.id} >
                                            <td>{item.nome}</td>
                                            <td>{item.tipo_tecido}</td>
                                            <td>{item.cor}</td>
                                            <td>{formatPrice(item.preco)}</td>
                                            <td>{formatPrice(item.preco - item.preco * (item.desconto / 100))} ({item.desconto})%</td>
                                            <td><img alt="imagem produto" src={url} className="img-listagem-produtos materialboxed" /></td>
                                        </tr>
                                    })
                                }

                            </tbody>
                        </table>

                    </div >
                </div >

            </div >
        } />
    </>);
};