import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import M from 'materialize-css';
import { ToastContainer } from 'react-toastify';

import { validSession } from "./utils/sessionStorage";

// import NotFound from "./paginas/NotFound/notFound";
// import Inicio from "./paginas/inicio/inicio";
// import DetalheProduto from "./paginas/detalheProduto/detalheProduto";
// import Carrinho from "./paginas/carrinho/carrinho";
// import Checkout from "./paginas/checkout/checkout";

import Login from "./paginas/admin/login/login";
import Vendas from "./paginas/admin/lancamentoVendas/lancamentoVendas";
import CadastroAtributo from "./paginas/admin/cadastroAtributo/cadastroAtributo";
import Estoque from "./paginas/admin/estoque/estoque";
import Preco from "./paginas/admin/preco/preco";
import Produtos from "./paginas/admin/produtos/listagem";


const App = () => {
  const [sessao, setSessao] = useState(false);
  const [css, setCss] = useState(false);

  window.scrollTo(0, 0);

  useEffect(() => {
    setSessao(validSession('2bw0FFmJOu'));
    M.AutoInit();
  }, [css]);



  return (<>


    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
    <BrowserRouter>

      <Routes>

        {/* <Route path='/404' component={NotFound} /> */}
        {/* <Route path="*" element={<Navigate replace to="/404" component={NotFound} />} /> */}

        {/* <Route path="/" element={<Inicio setCss={setCss}/>} /> */}
        {/* <Route path="/produto" element={<DetalheProduto />} />
        <Route path="/carrinho" element={<Carrinho />} />
        <Route path="/checkout" element={<Checkout />} /> */}


        < Route path="/" element={<Login />} />

        {/* ADMIN */}
        {sessao && < Route path="/admin/vendas" element={<Vendas />} />}
        {sessao && < Route path="/admin/cadastro/atributo" element={<CadastroAtributo />} />}
        {sessao && < Route path="/admin/estoque" element={<Estoque setCss={setCss} />} />}
        {sessao && < Route path="/admin/preco" element={<Preco setCss={setCss}/>} />}
        {sessao && < Route path="/admin/produtos" element={<Produtos setCss={setCss} />} />}
        {/* ADMIN */}


      </Routes>
    </BrowserRouter>

  </>);
};

export default App;
